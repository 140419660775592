/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

/* eslint-disable react/jsx-no-constructed-context-values */

'use client';

import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  useRef,
} from 'react';
import Toast from 'components/common/Toast';

// define the props
type NotificationSeverity = 'success' | 'info' | 'warning' | 'error';
type NotificationState = {
  severity: NotificationSeverity;
  message: string;
};
type NotificationContextProps = {
  createNotification: (notification: NotificationState) => void;
};

// Context with NotificationContextProps and initialize it to null
export const NotificationContext =
  createContext<NotificationContextProps | null>(null);

const useNotification = (): NotificationContextProps => {
  // use the useContext hook
  const context = useContext(NotificationContext);

  // Make sure it's not null!
  if (!context) {
    throw new Error('Please use NotificationProvider in parent component');
  }

  return context;
};

export function NotificationProvider(props: PropsWithChildren) {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const [severity, setSeverity] = useState<NotificationSeverity>('success');
  const {children} = props;
  const queueRef = useRef<(NotificationState | null)[]>([]);

  function processQueue() {
    if (queueRef.current.length > 0) {
      const notification = queueRef.current.shift();
      setMessage(notification?.message);
      setSeverity(notification?.severity || 'success');
      setOpen(true);
    }
  }

  // This should take the shape of an object
  // with two keys, message and severity
  function createNotification(notification: NotificationState) {
    queueRef.current.push(notification);

    if (open) {
      // If it is already open, close it
      setOpen(false);
    } else {
      // If not opened already, process your notification.
      processQueue();
    }
  }

  return (
    <NotificationContext.Provider value={{createNotification}}>
      {children}
      <Toast
        open={open}
        setOpen={setOpen}
        message={message}
        severity={severity}
      />
    </NotificationContext.Provider>
  );
}

export default useNotification;

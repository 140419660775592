/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

import {
  Option,
  DIST_B_CONTROL,
  DIST_C_CONTROL,
  DIST_D_CONTROL,
  DIST_E_CONTROL,
  DIST_F_CONTROL,
} from './upload';

export type DisseminationData = {
  distribution: string;
  letter: string;
  reasonsForControl: string[];
};

export type DistroAccess = {
  letter: string;
  publicAccess: boolean;
  govAgencies: boolean;
  govContractors: boolean;
  DoD: boolean;
  DoDContractors: boolean;
  controlList?: Option[];
  displayTips?: boolean;
};

export const DistroA: DistroAccess = {
  letter: 'A',
  publicAccess: true,
  govAgencies: true,
  govContractors: true,
  DoD: true,
  DoDContractors: true,
  controlList: [],
  displayTips: false,
};

export const DistroB: DistroAccess = {
  letter: 'B',
  publicAccess: false,
  govAgencies: true,
  govContractors: false,
  DoD: true,
  DoDContractors: false,
  controlList: DIST_B_CONTROL,
  displayTips: false,
};

export const DistroC: DistroAccess = {
  letter: 'C',
  publicAccess: false,
  govAgencies: true,
  govContractors: true,
  DoD: true,
  DoDContractors: true,
  controlList: DIST_C_CONTROL,
  displayTips: false,
};

export const DistroD: DistroAccess = {
  letter: 'D',
  publicAccess: false,
  govAgencies: false,
  govContractors: false,
  DoD: true,
  DoDContractors: true,
  controlList: DIST_D_CONTROL,
  displayTips: false,
};

export const DistroE: DistroAccess = {
  letter: 'E',
  publicAccess: false,
  govAgencies: false,
  govContractors: false,
  DoD: true,
  DoDContractors: false,
  controlList: DIST_E_CONTROL,
  displayTips: false,
};

export const DistroF: DistroAccess = {
  letter: 'F',
  publicAccess: false,
  govAgencies: false,
  govContractors: false,
  DoD: false,
  DoDContractors: false,
  controlList: DIST_F_CONTROL,
  displayTips: false,
};

export const DistroUnknown: DistroAccess = {
  letter: 'UNKNOWN',
  publicAccess: false,
  govAgencies: false,
  govContractors: false,
  DoD: false,
  DoDContractors: false,
  displayTips: false,
};
export const FEEDBACK_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSftRnmFXId0X4N5AHPtIJcRX4mVxg-QMZclSMSJofBMvCVsMQ/viewform?usp=sf_link';
export const HELP_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSeb8iHFOR1omkuG7r5kTeadpQg-xkZ7u7QFaJGwPD7kaYBRGQ/viewform?usp=sf_link';
export const DATA_CONNECTION_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSeVUsMoekJ6k1ux_yKwbpSGIcpxA9O2LMxiWbh-t-psR9p2lA/viewform';

export const ERROR_504 =
  '504: Failure due to gateway timeout.  Please try again.';
export const ERROR_401 = '401: Authorization failure.  Please try again.';
export const ERROR_GENERAL = 'Error encountered.  Please try again.';

export const MAX_CONTENT_WIDTH = 1525;
export const MAX_NAV_WIDTH = 1717;

export const NIPR_LIST = ['envision.af.mil'];

export const NLPFileTypes = [
  'application/pdf',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

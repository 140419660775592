/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

import {ERROR_401, ERROR_504, ERROR_GENERAL} from 'types/common';

export const DELVE_STORAGE_PATH = '/hyperthought-delve/api/';

export const fetcher = (url: string) =>
  fetch(url).then((r) => {
    if (r.status === 504) {
      throw new Error(ERROR_504);
    }

    if (r.status === 401) {
      throw new Error(ERROR_401);
    }

    if (!r.ok) {
      throw new Error(ERROR_GENERAL);
    }
    return r.json();
  });

export const handleArrayParams = (value: string | null) => {
  if (!value) return null;

  if (value.indexOf(',') > -1) {
    return value.split(',');
  }

  return [value];
};

export async function sendPatchRequest(url, {arg}) {
  return fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(arg),
  }).then((res) => res.json());
}

export async function sendPostRequest(url, {arg}) {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(arg),
  }).then((res) => res.json());
}

export async function sendRefreshRequest(url) {
  return fetch(url, {
    method: 'POST',
  }).then((res) => res.json());
}

export async function sendPutBRequest(url, {arg}) {
  return fetch(url, {
    method: 'PUT',
    body: JSON.stringify(arg),
  }).then((res) => res.json());
}

export async function sendGetRequest(url) {
  return fetch(url, {
    method: 'GET',
  }).then((res) => res.json());
}

export async function sendDeleteRequest(url, {arg}) {
  return fetch(url, {
    method: 'DELETE',
    body: JSON.stringify(arg),
  }).then((res) => res.json());
}

/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

import {FileWithPath} from 'react-dropzone';

export type BooleanIsChecked = {
  checked: boolean;
  value: boolean;
};

export type Metadata = {
  keyName: string;
  value: {type: string; link: string};
  unit: string;
  annotation: string;
};

export type KeyValuePair = {
  Key: string;
  Value: string;
  Annotation: string;
  Unit: string;
};

export interface FileWithVersion extends FileWithPath {
  version: string;
  displayTitle: string;
  createdDate: string;
}

export const DISS_RES_TOTAL = 7;

export type FileError = 'empty' | 'noVersion' | 'noDate' | null;

export type Data = {
  subject: string[];
  description: string;
  limitView: BooleanIsChecked;
  keywords: string[];
  keyValuePairs: Metadata[];
  note: string;
};

export type DataError = {
  subject: boolean;
  description: boolean;
  limitView: boolean;
  keywords: boolean;
};

export const DATA_INITIAL: Data = {
  subject: [],
  description: '',
  limitView: {checked: false, value: false},
  keywords: [],
  keyValuePairs: [],
  note: '',
};

export const DATA_ERROR_INITIAL: DataError = {
  subject: false,
  description: false,
  limitView: false,
  keywords: false,
};

export type TabState = {
  name: string;
  isActive?: boolean;
  hasError: boolean;
  isCurrent: boolean;
  isComplete?: boolean;
};

export type UploadTabType = 'single' | 'bulk';

export const DISTRO_A = 'distribution a';
export const DISTRO_B = 'distribution b';
export const DISTRO_C = 'distribution c';
export const DISTRO_D = 'distribution d';
export const DISTRO_E = 'distribution e';
export const DISTRO_F = 'distribution f';
export const DISTRO_UNKNOWN = 'distribution unknown';

// allowed indicates if the choice is allowed in upload
export const DISTRO_OPTIONS = [
  {
    label: 'Distribution level A - public access',
    value: DISTRO_A,
    allowed: true,
  },
  {
    label: "Distribution level B - Gov't agencies",
    value: DISTRO_B,
    allowed: true,
  },
  {
    label: "Distribution level C - Gov't agencies & contractors",
    value: DISTRO_C,
    allowed: true,
  },
  {
    label: 'Distribution level D - DoD & their contractors',
    value: DISTRO_D,
    allowed: true,
  },
  {label: 'Distribution level E - DoD', value: DISTRO_E, allowed: true},
  {
    label: 'Distribution level F - by request only',
    value: DISTRO_F,
    allowed: true,
  },
  {
    label: `I don't know/ I don't have a distribution level`,
    value: DISTRO_UNKNOWN,
    allowed: true,
  },
];

// allowed indicates if the choice is allowed for DOD_CTR in upload
export const DISTRO_OPTIONS_CTR = [
  {
    label: 'Distribution level A - public access',
    value: DISTRO_A,
    allowed: true,
  },
  {
    label: "Distribution level B - Gov't agencies",
    value: DISTRO_B,
    allowed: false,
  },
  {
    label: "Distribution level C - Gov't agencies & contractors",
    value: DISTRO_C,
    allowed: true,
  },
  {
    label: 'Distribution level D - DoD & their contractors',
    value: DISTRO_D,
    allowed: true,
  },
  {label: 'Distribution level E - DoD', value: DISTRO_E, allowed: false},
  {
    label: 'Distribution level F - by request only',
    value: DISTRO_F,
    allowed: true,
  },
  {
    label: `I don't know/ I don't have a distribution level`,
    value: DISTRO_UNKNOWN,
    allowed: true,
  },
];

export type Option = {
  value: string;
  label: string;
  allowed?: boolean;
};

export const DIST_B_CONTROL = [
  {value: 'CTI', label: 'Critical Technical Information'},
  {value: 'EXPT', label: 'Export Controlled'},
  {value: 'ADMIN', label: 'Administrative or Operational Use'},
  {value: 'BUDG', label: 'Budget'},
  {value: 'Contractor Performance Evaluation', label: ''},
  {value: 'FGI', label: 'Foreign Government Information'},
  {value: 'INTEL', label: 'General Intelligence'},
  {value: 'PRVCY', label: 'General Privacy'},
  {value: 'PROCURE', label: 'General Procurement and Acquisition'},
  {value: 'PROPIN', label: 'General Proprietary Business Information'},
  {value: 'ISVI', label: 'Information Systems Vulnerability Information'},
  {value: 'INTL', label: 'International Agreement Information'},
  {value: 'INVENT', label: 'Inventions'},
  {value: 'OPSEC', label: 'Operations Security'},
  {value: 'APP', label: 'Patent Applications'},
  {value: 'PERS', label: 'Personnel Records'},
  {value: 'PHYS', label: 'Physical Security'},
  {value: 'Premature Dissemination', label: ''},
  {value: 'Software Documentation', label: ''},
  {value: 'SSEL', label: 'Source Selection'},
  {value: 'Specific Authority', label: ''},
  {value: 'OITE', label: 'Test and Evaluation'},
  {value: 'CVI', label: 'Vulnerability Information'},
];

export const DIST_C_CONTROL = [
  {value: 'CTI', label: 'Critical Technical Information'},
  {value: 'EXPT', label: 'Export Controlled'},
  {value: 'ADMIN', label: 'Administrative or Operational Use'},
  {value: 'BUDG', label: 'Budget'},
  {value: 'Contractor Performance Evaluation', label: ''},
  {value: 'FGI', label: 'Foreign Government Information'},
  {value: 'INTEL', label: 'General Intelligence'},
  {value: 'PRVCY', label: 'General Privacy'},
  {value: 'PROCURE', label: 'General Procurement and Acquisition'},
  {value: 'ISVI', label: 'Information Systems Vulnerability Information'},
  {value: 'INTL', label: 'International Agreement Information'},
  {value: 'INVENT', label: 'Inventions'},
  {value: 'APP', label: 'Patent Applications'},
  {value: 'PERS', label: 'Personnel Records'},
  {value: 'PHYS', label: 'Physical Security'},
  {value: 'Software Documentation', label: ''},
  {value: 'SSEL', label: 'Source Selection'},
  {value: 'Specific Authority', label: ''},
  {value: 'CVI', label: 'Vulnerability Information'},
];

export const DIST_D_CONTROL = DIST_C_CONTROL;

export const DIST_E_CONTROL = [
  {value: 'CTI', label: 'Critical Technical Information'},
  {value: 'EXPT', label: 'Export Controlled'},
  {value: 'ADMIN', label: 'Administrative or Operational Use'},
  {value: 'BUDG', label: 'Budget'},
  {value: 'Contractor Performance Evaluation', label: ''},
  {value: 'Direct Military Support', label: ''},
  {value: 'FGI', label: 'Foreign Government Information'},
  {value: 'INTEL', label: 'General Intelligence'},
  {value: 'PRVCY', label: 'General Privacy'},
  {value: 'PROCURE', label: 'General Procurement and Acquisition'},
  {value: 'PROPIN', label: 'General Proprietary Business Information'},
  {value: 'ISVI', label: 'Information Systems Vulnerability Information'},
  {value: 'INTL', label: 'International Agreement Information'},
  {value: 'INVENT', label: 'Inventions'},
  {value: 'OPSEC', label: 'Operations Security'},
  {value: 'APP', label: 'Patent Applications'},
  {value: 'PERS', label: 'Personnel Records'},
  {value: 'PHYS', label: 'Physical Security'},
  {value: 'Premature Dissemination', label: ''},
  {value: 'Software Documentation', label: ''},
  {value: 'SSEL', label: 'Source Selection'},
  {value: 'Specific Authority', label: ''},
  {value: 'OITE', label: 'Test and Evaluation'},
  {value: 'CVI', label: 'Vulnerability Information'},
];

export const DIST_F_CONTROL = DIST_E_CONTROL;
